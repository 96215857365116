

































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import { ProduktModel } from "@/models/ProduktModel";
import { VCAImportFehlerModel } from "@/models/VCAImportFehlerModel";
import { ZeitschriftFlatModel } from "@/models/ZeitschriftDataModel";
import { catVue } from "@/util/logging";

// eslint-disable-next-line

   interface ZtgFix {
     produkt: ProduktModel;
     details: ZeitschriftFlatModel | null;
   }

   @Component({})
   export default class VCAFehlerFixer extends Vue {
     //

     loading = false;
     loadingDetails = false;
     produkt = "";
     selection = "";
     error = "";
     success = "";
     liste = [] as ZtgFix[];

     // Mehr zeige ich nicht an.
     MAX = 16;

     @Prop() private item!: VCAImportFehlerModel;

     created(): void {
       catVue.info(`${this.$options.name}:: CREATED ... ${this.item}`);
     }

     mounted(): void {
       catVue.info(`${this.$options.name}:: MOUNTED ... ${this.item}`);
     }

     async doSearch(): Promise<void> {
       catVue.info(`doSearch called … ${this.produkt}`);
       this.produkt = this.produkt.normalize().trim();
       if (this.produkt.length > 0) {
         try {
           this.loading = true;
           this.selection = "";

           this.liste.splice(0);
           const tmpListe = await ProduktModel.find(this.produkt, "zeitschrift");
           if (tmpListe.length > 0) {
             tmpListe.forEach((ztg) => {
               this.loadDetails(ztg, tmpListe.length <= this.MAX);
             });
           }
           this.loading = false;
         } catch (e) {
           catVue.info("Error in Product Fetch: " + e);
           this.loading = false;
         }
       }
     }

     loadDetails(ztg: ProduktModel, load: boolean): void {
       const fix: ZtgFix = {
         produkt: ztg,
         details: null,
       };

       this.liste.push(fix);

       if (!load) return;

       ZeitschriftFlatModel.byURL(ztg.url)
         .then((ztg) => {
           catVue.info(`loadDetails success … ${ztg}`);
           fix.details = ztg;
         })
         .catch((e) => {
           catVue.info(`loadDetails error … ${e}`);
         });
     }

     doAssign(): void {
       catVue.info(`doAssign called … ${this.selection}`);
       if ("" == this.selection) return;

       this.loading = true;
       this.error = "";
       this.success = "";

       this.liste.forEach((fix) => {
         if (fix.produkt.uid == this.selection) {
           if (undefined === fix.details || null === fix.details) {
             catVue.info(`doAssign error with fix entry … ${JSON.stringify(fix)}`);
             return;
           }

           if (this.item.fromErrorfile) {
             //
             catVue.info(`patching from errorFile`);
             this.item
               .patch("set-vca:" + fix.details.vca_id)
               .then((err) => {
                 catVue.info(`doAssign success … ${err}`);
                 if (err !== null) this.item = err;
                 this.loading = false;
                 this.success = "Assigned VCA-ID " + err?.vcaID;
               })
               .catch((e) => {
                 catVue.info(`doAssign error … ${e}`);
               });
           } else {
             //
             catVue.info(`patching from ergebnisFile`);
             fix.details
               ?.patch("set-vca:" + this.item.vcaID)
               .then((ztg) => {
                 catVue.info(`doAssign success … ${ztg}`);
                 fix.details = ztg;
                 this.loading = false;
                 this.success = "Assigned VCA-ID " + ztg?.vca_id;
               })
               .catch((e) => {
                 catVue.info(`doAssign error … ${e}`);
               });
           }
         }
       });
     }

     doRetry(): void {
       catVue.info(`doRetry called … ${this.produkt}`);
     }
   }

   Vue.component("vca-fehler-fixer", VCAFehlerFixer);
   //  store.commit("changeAppLoadingState", true, {root: true});
   //  window.setTimeout( () =>   store.commit("changeAppLoadingState", false, {root: true}), 10000)
